import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

export function useMobile() {
  const [isMobile, setIsMobile] = useState(false);
  const mobile = useMediaQuery({ query: '(max-width:768px)' });

  useEffect(() => {
    setIsMobile(mobile);
    window.dispatchEvent(new Event('resize'));
  }, [mobile]);

  return isMobile;
}

export function useTablet() {
  const [isTablet, setIsTablet] = useState(false);
  const tablet = useMediaQuery({ query: '(max-width:1024px)' });

  useEffect(() => {
    setIsTablet(tablet);
    window.dispatchEvent(new Event('resize'));
  }, [tablet]);

  return isTablet;
}
