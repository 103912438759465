export function IsTripbtozApp() {
  if (window) {
    const isApp = window.navigator.userAgent.indexOf("Tripbtoz") > -1;
    const isNewApp =
      window.navigator.userAgent.indexOf("TripbtoziOS/3.0/MetaTrip") > -1 ||
      window.navigator.userAgent.indexOf("TripbtozAndroid/3.0/MetaTrip") > -1;
    return isApp || isNewApp;
  } else {
    return false;
  }
}


export function IsTTBBApp() {
  if (typeof window !== 'undefined') {
    const isApp = window.navigator.userAgent.indexOf("TTBB") > -1;
    const isNewApp =
      window.navigator.userAgent.indexOf("TTBBiOS/3.0/MetaTrip") > -1 ||
      window.navigator.userAgent.indexOf("TTBBAndroid/3.0/MetaTrip") > -1;
    return isApp || isNewApp;
  } else {
    return false;
  }
}

export function IsKBApp() {
  if (typeof window !== 'undefined') {
    const isApp = window.navigator.userAgent.indexOf("KBPAYIA") > -1;
    return isApp;
  } else {
    return false;
  }
}
